import { lang, sessid } from '../../common/baseData'
import axios from 'axios'

class Analytics {
  url = '/local/analytics.ajax.php'
  config = { headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' } }

  selectItem (item_id, list_name = '', list_id = '') {
    let body = JSON.stringify({
      event: 'selectItem',
      event_data: {
        item_id: item_id,
        list_name: list_name,
        list_id: list_id,
      },
      lang: lang,
      sessid: sessid
    })

    return axios.post(this.url, body, this.config)
  }

  view_cart (totalPrice, items_params = {}) {
    // Measure when a product is added to a shopping cart

    //Добавляем в событие данные из цепочки переходов по товару
    items_params = this.enrichItemsArrayFromStorage(items_params);

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: "view_cart",
      ecommerce: {
        currency: 'UAH',
        value: totalPrice,
        items: items_params
      }
    });
  }

  /**
   * Обогащает параметры товара данными из localStorage ga_products.
   *
   * @param {string|number} item_id - ID товара для поиска в localStorage
   * @param {Object} item_params - Параметры товара, которые могут быть обогащены
   * @returns {Object} - Обогащенные параметры товара
   */
  enrichItemParamsFromStorage(item_id, item_params = {}) {
    try {
      const storedProducts = localStorage.getItem('ga_products');
      if (storedProducts) {
        const gaProducts = JSON.parse(storedProducts);

        // Проверяем, существует ли запись с таким item_id
        if (gaProducts[item_id]) {
          const storedProductData = gaProducts[item_id];

          // Объединяем данные из localStorage с переданными параметрами
          // Переданные параметры имеют приоритет
          return {
            ...storedProductData,  // Данные из localStorage
            ...item_params         // Переданные параметры (имеют приоритет)
          };
        }
      }

      // Если данных в localStorage нет, возвращаем исходные параметры
      return item_params;
    } catch (error) {
      console.error('Error enriching item params from localStorage:', error);
      return item_params; // В случае ошибки возвращаем исходные параметры
    }
  }

  /**
   * Обогащает параметры массива товаров данными из localStorage ga_products.
   *
   * @param {Array<Object>} items - Массив объектов товаров для обогащения
   * @returns {Array<Object>} - Массив обогащенных объектов товаров
   */
  enrichItemsArrayFromStorage(items) {
    if (!items || !Array.isArray(items) || items.length === 0) {
      return items;
    }

    try {
      // Получаем данные из localStorage один раз для всего массива
      const storedProducts = localStorage.getItem('ga_products');
      if (!storedProducts) {
        return items; // Если нет данных, возвращаем исходный массив
      }

      const gaProducts = JSON.parse(storedProducts);

      // Обрабатываем каждый элемент массива
      return items.map(item => {
        const item_id = item.item_id;

        // Если нет item_id или нет данных для этого товара, оставляем как есть
        if (!item_id || !gaProducts[item_id]) {
          return item;
        }

        // Объединяем данные
        return {
          ...gaProducts[item_id], // Данные из localStorage
          ...item                 // Данные из переданного объекта (приоритетные)
        };
      });
    } catch (error) {
      console.error('Error enriching items array from localStorage:', error);
      return items; // В случае ошибки возвращаем исходный массив
    }
  }

  viewItem (item_id, item_params = {}) {
    // Measure a view of product details. This example assumes the detail view occurs on pageload,

    //Добавляем в событие данные из цепочки переходов по товару
    item_params = this.enrichItemParamsFromStorage(item_id, item_params);

    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        items: [item_params]
      }
    });

    return axios.post(this.url, JSON.stringify({
      sessid: sessid,
      event: 'viewItem',
      event_data: {
        item_id: item_id
      }
    }), this.config)
  }

  viewItemList (item_ids, list_name = '', list_id = '') {
    return new Promise(resolve => {
      let body = JSON.stringify({
        event: 'viewItemList',
        event_data: {
          item_ids: item_ids,
          list_name: list_name,
          list_id: list_id,
        },
        lang: lang,
        sessid: sessid
      })

      axios.post(this.url, body, this.config)
        .then(response => {
          resolve(response.data)
        })
        .catch(reason => {
          console.log(reason)
          resolve()
        })
    })
  }

  /**
   * Tracks the action of adding an item to the shopping cart and sends relevant data to the data layer.
   *
   * @param {Object} params - The parameters related to the "add to cart" event.
   * @param {number} params.total_price - The total price of the items added to the cart.
   * @param {Object} params.item - The item being added to the cart, including its details (e.g., id, name, category).
   * @return {void} This method does not return a value.
   */
  add_to_cart(params) {
    //Добавляем в событие данные из цепочки переходов по товару
    params.item = this.enrichItemParamsFromStorage(params.item.item_id, params.item);

    // Measure when a product is added to a shopping cart
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "UAH",
        value: params.total_price,
        items: [params.item]
      }
    });
  }

  /**
   * Tracks the removal of an item from the shopping cart and updates the analytics data layer.
   *
   * @param {Object} params - The parameters for removing an item from the cart.
   * @param {Object} params.item - The item being removed from the cart.
   * @param {number} params.total_price - The total price of the cart after the item is removed.
   * @return {void} This method does not return a value.
   */
  remove_from_cart(params) {
    // Measure when a product is added to a shopping cart
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        currency: "UAH",
        value: params.total_price,
        items: [params.item]
      }
    });
  }

  /**
   * Adds an item to the wishlist and updates the dataLayer with the event.
   *
   * @param {Object} params - The parameters for adding to the wishlist.
   * @param {Object} params.item - The item object to be added to the wishlist, containing relevant product details.
   * @return {void} This function does not return a value.
   */
  add_to_wishlist(params) {
    params.item.quantity = 1;
    // Measure when a product is added to a shopping cart
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      event: "add_to_wishlist",
      ecommerce: {
        items: [params.item]
      }
    });
  }

  /**
   * Initiates the "begin checkout" event and updates the ecommerce data layer with relevant transaction details.
   *
   * @param {Object} params - The parameters containing checkout details.
   * @param {number} params.total_price - The total price of items in the checkout.
   * @param {Object} params.item - An object representing the specific item in the cart.
   * @return {void} This function does not return any value.
   */
  begin_checkout(params){

    //Добавляем в событие данные из цепочки переходов по товару
    params.items = this.enrichItemsArrayFromStorage(params.items);

    // Measure when a product is added to a shopping cart
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        currency: "UAH",
        value: params.total_price,
        items: [params.items]
      }
    });
  }

  addToCart (item_id) {
    let body = JSON.stringify({
      event: 'addToCart',
      event_data: {
        item_id: item_id
      },
      lang: lang,
      sessid: sessid
    })

    axios.post(this.url, body, this.config)
      .then(response => {
        //console.dir(response)
      })
  }

  /**
   *
   * @param orderId
   * @returns {Promise<Object>}
   */
  purchase (orderId) {
    return new Promise((resolve) => {
      axios.post(this.url, JSON.stringify({
        event: 'orderPurchase',
        event_data: { order_id: orderId },
        lang: lang,
        sessid: sessid
      }), this.config)
        .then(response => {
          console.info(response)
          if (response.data !== null && !response.data.hasOwnProperty('error')) {
            window.dataLayer.push({ ecommerce: null })
            window.dataLayer.push({
              event: 'purchase',
              ecommerce: response.data
            })
          }
          resolve()
        })
        .catch(reason => {
          console.log(reason)
          resolve()
        })
    })
  }

  /**
   * Sends a 'select_item' event to the dataLayer with the provided ecommerce parameters.
   * Clears the previous ecommerce object before pushing the new event data.
   *
   * @param {Object} params - The parameters related to the selected item, structured as per the ecommerce specifications.
   * @return {void} - This method does not return any value.
   */
  select_item(params){
    this.updateGAProductsList(params);

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      event: "select_item",
      ecommerce: params
    });
  }

  /**
   * Updates or adds product information in localStorage based on provided params.
   *
   * @param {Object} params - Object containing product information
   * @param {number} params.ga_session_id - Google Analytics session ID
   * @param {string} params.item_list_id - List identifier
   * @param {string} params.item_list_name - List name
   * @param {Array} params.items - Array of product items
   * @return {void}
   */
  updateGAProductsList(params) {
    // Проверяем наличие необходимых данных
    if (!params || !params.items || !params.items.length) {
      console.warn('No valid items data provided for GA products update');
      return;
    }

    // Для каждого элемента в массиве items
    params.items.forEach(item => {
      const item_id = item.item_id;
      if (!item_id) {
        console.warn('Item without item_id found, skipping');
        return;
      }

      // Получаем текущие данные из localStorage или инициализируем пустой объект
      let gaProducts = {};
      try {
        const storedProducts = localStorage.getItem('ga_products');
        if (storedProducts) {
          gaProducts = JSON.parse(storedProducts);
        }
      } catch (error) {
        console.error('Error parsing ga_products from localStorage:', error);
      }

      // Проверяем существует ли запись с таким item_id
      if (gaProducts[item_id]) {
        // Обновляем существующую запись
        gaProducts[item_id].item_list_id = params.item_list_id;
        gaProducts[item_id].item_list_name = params.item_list_name;
      } else {
        // Создаем новую запись
        gaProducts[item_id] = {
          item_id: item_id,
          item_list_id: params.item_list_id,
          item_list_name: params.item_list_name,
        };
      }

      // Сохраняем обновленные данные в localStorage
      try {
        localStorage.setItem('ga_products', JSON.stringify(gaProducts));
        this.saveGAProducts(gaProducts);
      } catch (error) {
        console.error('Error saving ga_products to localStorage:', error);
      }
    });
  }

  /**
   * Sends Google Analytics products data to the server.
   *
   * @param {Array} gaProducts - The array of Google Analytics product data to be sent to the server.
   * @return {Promise} Returns a promise that resolves with the server response or rejects with an error if the request fails.
   */
  sendGAProductsToServer(gaProducts) {
    return axios.post('/local/api/saveGaProducts.php', {
      ga_products: gaProducts
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  /**
   * Saves the GA products to both browser's local storage and session storage,
   * and sends them to the server for further processing and saving in a PHP session.
   *
   * @param {Object[]} gaProducts - An array of GA product objects to be saved.
   * @return*/
  saveGAProducts(gaProducts) {
    try {
      // Сохраняем в локальные хранилища браузера
      const jsonData = JSON.stringify(gaProducts);
      localStorage.setItem('ga_products', jsonData);
      sessionStorage.setItem('ga_products', jsonData);

      // Отправляем на сервер для сохранения в PHP сессию
      this.sendGAProductsToServer(gaProducts);

      return true;
    } catch (error) {
      console.error('Error saving ga_products:', error);
      return false;
    }
  }

  /**
   * Removes a single product from ga_products in localStorage.
   *
   * @param {string|number} item_id - ID of the item to remove
   * @return {boolean} - True if item was successfully removed, false otherwise
   */
  removeGAProduct(item_id) {
    if (!item_id) {
      console.warn('No valid item_id provided for GA product removal');
      return false;
    }

    // Получаем текущие данные из localStorage
    let gaProducts = {};
    try {
      const storedProducts = localStorage.getItem('ga_products');
      if (storedProducts) {
        gaProducts = JSON.parse(storedProducts);
      } else {
        console.log('No ga_products found in localStorage, nothing to remove');
        return false;
      }
    } catch (error) {
      console.error('Error parsing ga_products from localStorage:', error);
      return false;
    }

    // Проверяем существует ли запись с таким item_id
    if (gaProducts[item_id]) {
      // Удаляем запись
      delete gaProducts[item_id];

      // Сохраняем обновленные данные в localStorage
      try {
        localStorage.setItem('ga_products', JSON.stringify(gaProducts));
        console.log(`Product with ID ${item_id} removed from ga_products`);
        return true;
      } catch (error) {
        console.error('Error saving ga_products to localStorage after removal:', error);
        return false;
      }
    } else {
      console.log(`Product with ID ${item_id} not found in ga_products`);
      return false;
    }
  }

  view_item_list(params){
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      event: "view_item_list",
      ecommerce: params
    });
  }
}

export default Analytics