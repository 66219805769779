import {
  eventEmitter,
  sessid,
  lang,
  esputnikTracker,
  isAuth,
  fuserId,
  analytics
} from '../common/baseData';

export class ProductWishlist {
  constructor(params) {
    let self = this;

    this.isAuth = isAuth;
    this.fuserId = fuserId;
    this.sessid = sessid;

    this.dataList = {};
    this.nodeList = {};

    this.loadUrl = '/local/components/webhome/favorite.products/ajax.php';
    this.reloadUrl = '/local/templates/tehnoezh-modern/ajaxComponentLoader.php';

    /** @private {_storage} **/
    this._storage;
    // Set storage to sessionStorage
    this.storage = window.sessionStorage;

    //Receives dom elements with which (and only with them) will work
    eventEmitter.on('handleWishlistItems', params => {
      if (params.hasOwnProperty('node') && params.hasOwnProperty('range'))
        self.handleWishlistItems(params.node, params.range);
    });

    this.checkUserSession();
  }

  /** @return {window.sessionStorage} **/
  get storage() {
    return this._storage;
  }

  /** @param {window.sessionStorage} storage **/
  set storage(storage) {
    this._storage = storage;
  }

  checkUserSession() {
    let self = this;
    if (this.storage.getItem('favoriteProduct')) {
      try {
        this.add(Number(this.storage.getItem('favoriteProduct'))).
          then(response => {
            self.clearStorage();
          });
      } catch (e) {
        console.error(e);
      }

    }
  }

  clearStorage() {
    this.storage.clear();
  }

  isCorrectItem(node) {
    return ((node.dataset.hasOwnProperty('id') && Number(node.dataset.id) > 0)
      && (node.dataset.hasOwnProperty('container') && node.dataset.container ===
        'favorite'));
  }

  handleWishlistItems(node, range) {
    console.log('handleWishlistItems', node, range);
    try {
      let items = [], dataId = [];
      let dataItems = {};
      let self = this;
      self.dataList = {};

      if (node instanceof HTMLElement)
        items.push(node);
      else if (node instanceof NodeList)
        items = Array.prototype.slice.call(node);

      if (items.length <= 0) return;

      items.forEach(item => {
        if (!self.isCorrectItem(item) ||
          self.dataList.hasOwnProperty(item.dataset.id)) return;
        self.dataList[item.dataset.id] = false;
        dataId.push(Number(item.dataset.id));

        let showMe = false;
        item.addEventListener('click', e => {
          e.preventDefault();

          let favoriteLink = item.parentNode;

          if (showMe || favoriteLink.classList.contains('active')) {
            // add class active for all fav icon , when click on
            if (this.isAuth) {
              let wishListIconList = document.querySelectorAll(
                '.links-box .favorite-link');
              for (let i = 0; i < wishListIconList.length; i++) {
                wishListIconList[i].classList.remove('active');
              }
              favoriteLink.classList.remove('active');
            }

            showMe = false;
            self.remove(e.target.dataset.id);
          } else {
            // add class active for all fav icon , when click on
            if (this.isAuth) {
              let wishListIconList = document.querySelectorAll(
                '.links-box .favorite-link');
              for (let i = 0; i < wishListIconList.length; i++) {
                wishListIconList[i].classList.add('active');
              }
              favoriteLink.classList.add('active');
            }
            showMe = true;
            self.onClickItem(e.target);
          }
        });

        dataItems[item.dataset.id] = item;
      });

      this.nodeList[range] = dataItems;

      if (this.isAuth) {
        this.sendRequest({
          action: 'getList',
          filterId: dataId,
        }).then(response => {
          return response.json();
        }).then(result => self.processResultDataBase(result));
      }
    } catch (e) {
      console.error(e);
    }
  }

  processResultDataBase(data) {
    if (!data.hasOwnProperty('items')) return;
    const items = data.items;

    for (let i in items) {
      if (!items.hasOwnProperty(i)) continue;
      this.dataList[items[i]] = true;

      for (let range in this.nodeList) {
        if (!this.nodeList.hasOwnProperty(range)
          || !this.nodeList[range].hasOwnProperty(items[i])) continue;

        if (this.nodeList[range][items[i]] instanceof HTMLElement)
          this.nodeList[range][items[i]].parentNode.classList.add('active');
      }
    }
  }

  loadItems() {
    return this.sendRequest({action: 'getTotalList'});
  }

  reload(params) {
    params.loadUrl = this.reloadUrl;
    return this.sendRequest(params);
  }

  onClickItem(node) {
    //Not auth user
    if (!this.isAuth) {
      sessionStorage.setItem('favoriteProduct', node.dataset.id);
      eventEmitter.emit('showAuth', 'favorite');
      //Check product id in dataList
    } else if (this.dataList.hasOwnProperty(node.dataset.id)) {
      this.add(Number(node.dataset.id)).then(result => {
        if (result.hasOwnProperty('status') && result.status === 'ok') {
          node.parentNode.classList.add('active');
          this.dataList[node.dataset.id] = true;
        } else if (result.hasOwnProperty('error')) {
          console.dir(result.error);
        }
      });
    }
  }

  clear() {
    this.sendRequest({action: 'clear'}).then(response => {
      eventEmitter.emit('refreshWishList',
        {eventType: 'clearWishList', items: []});
      return response.json();
    });
  }

  add(productId) {
    return this.sendRequest({action: 'add', productId: productId}).
      then(response => response.json()).
      then(response_data => {

        eventEmitter.emit(
          'refreshWishList',
          {eventType: 'addToWishList', items: response_data.items}
        );

        esputnikTracker.AddToWishlist(
          productId,
          response_data.price,
          response_data.inStock);

        if (response_data.productInfo.ga4) {
          analytics.add_to_wishlist(response_data.productInfo.ga4)
        }

        return response_data;
      });
  }

  remove(productId) {
    this.sendRequest({action: 'remove', productId: productId}).
      then(response => (response.json())).
      then(response_data => {
        eventEmitter.emit('refreshWishList', {eventType: 'removeFromWishList', items: response_data.items});
      });
  }

  sendRequest(data) {
    let formData = new FormData();
    formData.append('sessid', sessid);
    formData.append('lang', lang);
    formData.append('fuserId', this.fuserId);

    for (let i in data) {
      if (!data.hasOwnProperty(i) || i === 'loadUrl') continue;
      formData.append(i, data[i]);
    }

    return fetch((data.hasOwnProperty('loadUrl')) ? data.loadUrl : this.loadUrl,
      {body: formData, method: 'POST'});
  }
}